import React, { useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Stopwatch from "../components/Stopwatch";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RoomDialog from "../components/RoomDialog";
import SoundClip from "../components/SoundClip";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PubSub } from "aws-amplify";
import { insertError } from "../libs/hooksLib";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 10
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  actionGrid: {
    fontSize: 44,
    fontWeight: 'bold'
  },
  inRoomBtn: {
    color: "#fff",
    padding: '5px',
    backgroundColor: '#FF4081',
    fontSize: 32,
  }
}));

export default function SubRoom(props) {
  const [open, setOpen] = React.useState(false);
  const currentTime = new Date();
  const [loading, setLoading] = React.useState(false);
  const [duration, setDuration] = React.useState("");
  const classes = useStyles();
  const [packages, setPackages] = useState("");
  const [assetList, updateAssetList] = useState("");
  const [room, setRoom] = useState("");
  const [play, setPlay] = useState({ state: false, data: '' });
  const [pubSub, setPubSub] = useState("");
  const [myColor, setMyColor] = React.useState({ backgroundColor: 'white' });
  var apiURL = "https://9u7t3haok0.execute-api.ap-southeast-1.amazonaws.com/prod";
  const [errorOpen, setErrorOpen] = React.useState(false);

  const handleErrorClick = () => {
    setErrorOpen(true);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
  };

  function handleDialogClick() {
    setOpen(true);
  };

  function handleRefresh() {
    window.location.reload();
  };

  async function extendRoom(startTime, endTime, price, roomId, assetName, extendPrice) {
    setLoading(true);
    setMyColor({ backgroundColor: 'white' });

    fetch(apiURL + `/rooms/${roomId}`, {
      method: "put",
      headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
      body: JSON.stringify({
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        price: price,
        extend: true,
        extendPrice: extendPrice
      })
    })
      .then(async response => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          handleErrorClick();
          return Promise.reject(error);
        }
        return;
      })
      .catch(error => {
        handleErrorClick();
        insertError(error);
      });


    await PubSub.publish(["main", roomId], { msg: 'Timer Extended' });

    setLoading(false);
  }

  useEffect(() => {
    PubSub.subscribe(props.username).subscribe({
      next: data => { setPubSub(data); },
      error: error => console.error(error),
      close: () => console.log('Done'),
    });
  }, []);


  useEffect(() => {

    setPlay({ state: false, data: '' });

    PubSub.subscribe('reminder' + props.username).subscribe({
      next: data => { setPlay({ state: true, data: Math.random() }); },
      error: error => console.error(error),
      close: () => console.log('Done'),
    });

    if (pubSub !== "" && pubSub !== undefined) {
      window.location.reload();
    } else {
      setDuration("");
      setMyColor({ backgroundColor: 'white' });
    }

    async function onLoad() {
      setLoading(true);

      try {
        fetch(apiURL + '/rooms')
          .then(async response => {
            const roomdata = await response.json();
            setRoom(roomdata.data[roomdata.data.findIndex(obj => obj.roomId === props.username)]);
            const timeDiff = new Date(roomdata.data[roomdata.data.findIndex(obj => obj.roomId === props.username)].endTime) - currentTime;
            setDuration(timeDiff / 1000);
            // check for error response
            fetch(apiURL + '/assets')
              .then(async response => {
                const data = await response.json();
                updateAssetList(data.data.filter(it => roomdata.data[roomdata.data.findIndex(obj => obj.roomId === props.username)].roomName === it.tracking));

                if (!response.ok) {
                  const error = (data && data.message) || response.statusText;
                  handleErrorClick();
                  return Promise.reject(error);
                }
              })
              .catch(error => {
                insertError(error);
              });

            if (!response.ok) {
              const error = (roomdata && roomdata.message) || response.statusText;
              handleErrorClick();
              return Promise.reject(error);
            }
          })
          .catch(error => {
            handleErrorClick();
            insertError(error);
          });

        fetch(apiURL + '/packages')
          .then(async response => {
            const data = await response.json();
            setPackages(data.data.sort((a, b) => (a.price - b.price)));
            if (!response.ok) {
              const error = (data && data.message) || response.statusText;
              handleErrorClick();
              return Promise.reject(error);
            }
          })
          .catch(error => {
            handleErrorClick();
            insertError(error);
          });
      } catch (e) {
        handleErrorClick();
        insertError(e);
      }
      setLoading(false);
    }
    onLoad();

  }, [pubSub]);

  return (
    <Container style={{ maxWidth: "100%", paddingTop: "15px" }}>
      <Card className={classes.root}>
        <CardContent style={myColor}>
          <Grid container spacing={3}>
            <Grid item xs={10} sm={10}>
              <Typography variant="h4" color="primary">
                {room && room.roomName}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} align="right" />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4} align="left">
              {(duration !== "" && assetList.length !== 0) ?
                <Stopwatch
                  subRoom
                  time={duration}
                  setPlay={setPlay}
                  myColor={myColor}
                  setMyColor={setMyColor}
                />
                : <Typography color="textSecondary" align="left" variant="h1">--:--</Typography>
              }

            </Grid>
            <Grid item xs={4} sm={4} align="left">
              <Button className={classes.inRoomBtn} onClick={handleRefresh}> In Room </Button>
            </Grid>

            <Grid item xs={4} sm={4}>
              <Typography color="textSecondary" variant="h6">Current Package</Typography>
              {assetList !== "" &&
                <Typography color="textSecondary" variant="h3">S${room.price * assetList.length}</Typography>
              }
            </Grid>
          </Grid>

          <Button
            className={classes.actionGrid}
            fullWidth
            variant="outlined"
            size="large"
            color="primary"
            disabled={((room !== "" && packages !== "") && !packages.filter(it => room.price < it.price).length > 0) || room.price === 0 || assetList.length > 1}
            onClick={handleDialogClick}
          >
            Extend
          </Button>
          <RoomDialog
            subRoom
            open={open}
            setLoading={setLoading}
            onClose={setOpen}
            extendRoom={extendRoom}
            roomId={room !== "" && room.roomId}
            currentPrice={room !== "" && room.price}
            extendPrice={room !== "" && room.extendPrice}
            startTime={room !== "" && room.startTime}
            endTime={room !== "" && room.endTime}
            assetName={(room !== "" && assetList !== "" && assetList.length > 0) && assetList[0].assetName}
            packages={(room !== "" && packages !== "") && packages}
            setDuration={setDuration}
          />
        </CardContent>
      </Card>
      <SoundClip play={play} />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          Oops something went wrong. Please try again.
        </Alert>
      </Snackbar>
    </Container>
  );
}
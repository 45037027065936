import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AssetGridCircles from "../components/AssetGridCircles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import MenuDialog from "../components/MenuDialog";
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TouchAppIcon from '@material-ui/icons/TouchApp';

const useStyles = makeStyles(theme => ({
    actionGrid: {
        border: '1px dashed rgba(0, 0, 0, 0)',
        backgroundColor: '#ff9c61',
        minHeight: '60px',
        align: 'center',
        textAlign: 'center',
        padding: '15px'
    },
    card: {
        border: '1px solid rgba(0, 0, 0, 1)'
    }
}));

export default function AssetGrid(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    function handleChange(event) {
        event.preventDefault();
        setOpen(true);
    };

    return (
        <Grid>
            <MenuDialog
                open={open && props.selectedAsset !== ""}
                onClose={setOpen}
                voidTransaction={props.voidTransaction}
                updateTracking={props.updateTracking}
                createTransaction={props.createTransaction}
                updateTransactionEndTime={props.updateTransactionEndTime}
                assetId={props.selectedAsset !== "" && props.assetList[props.selectedAsset].assetId}
                assetName={props.selectedAsset !== "" && props.assetList[props.selectedAsset].assetName}
                tracking="Available"
                setSelectedAsset={props.setSelectedAsset}
                content={props.selectedAsset !== "" && (props.assetList[props.selectedAsset].assetName + " is available now.")}
            />
            <Box pt={2} />
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5">Available Girls</Typography>
                    <Box pt={2} />
                    {props.assetList.length > 0 &&
                        <AssetGridCircles
                            override={false}
                            selectedAsset={props.selectedAsset}
                            setSelectedAsset={props.setSelectedAsset}
                            tracking="Available"
                            assetList={props.assetList.filter(it => "Available" === it.tracking)}
                        />
                    }
                    <Box pt={2} />
                    <Button
                        fullWidth
                        className={classes.actionGrid}
                        onClick={handleChange}
                    >
                        <TouchAppIcon color="disabled" fontSize="large" />
                        <Typography color="textSecondary" variant="h6">Tap Here</Typography>
                    </Button>
                </CardContent>
            </Card>
        </Grid>
    );
}
import 'date-fns';
import React, { useEffect } from "react";
import Table from "../components/Table";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { API } from "aws-amplify";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { insertError } from "../libs/hooksLib";

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
        padding: theme.spacing(2),
        backgroundColor: '#fff',
    }
}));

const convertStartDate = date => {
    let newDate = new Date(date).setHours(9);
    return newDate;
}

const convertEndDate = date => {
    let newDate = new Date(date).setHours(33);
    return newDate;
}
const dateFormat = (props) => {
    var options = { month: 'short', day: '2-digit' };
    var today = new Date(props);
    return today.toLocaleDateString("en-US", options) // Saturday, September 17, 2016
}

const toLocalDate = (props) => {
    return new Date(props).toISOString().slice(0, 19) + 'Z'
}

export default function Transactions(props) {
    const classes = useStyles();
    const [selectedAssetName, setSelectedAssetName] = React.useState("All");
    const currentDate = new Date();
    const [selectedStartDate, setSelectedStartDate] = React.useState(currentDate - 1000 * 60 * 60 * 24 * 7);
    const [selectedEndDate, setSelectedEndDate] = React.useState(currentDate);
    const [transactions, updateTransactions] = React.useState("");
    const [displayTransactions, setDisplayTransactions] = React.useState("");
    const [assetList, updateAssetList] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleStartDateChange = date => {
        setSelectedStartDate(date);
        selectedAssetName === "All" ? setDisplayTransactions(transactions.filter(it => ((new Date(it.startTime) >= new Date(convertStartDate(date)) && new Date(it.startTime) <= new Date(convertEndDate(selectedEndDate))) && (new Date(it.endTime) <= new Date(convertEndDate(selectedEndDate)) || it.endTime === "-"))))
            : setDisplayTransactions(transactions.filter(it => ((new Date(it.startTime) >= new Date(convertStartDate(date)) && new Date(it.startTime) <= new Date(convertEndDate(selectedEndDate))) && it.assetName === selectedAssetName && (new Date(it.endTime) <= new Date(convertEndDate(selectedEndDate)) || it.endTime === "-"))))
    };

    const handleEndDateChange = date => {
        setSelectedEndDate(date);
        selectedAssetName === "All" ? setDisplayTransactions(transactions.filter(it => ((new Date(it.startTime) >= new Date(convertStartDate(selectedStartDate)) && new Date(it.startTime) <= new Date(convertEndDate(date))) && (new Date(it.endTime) <= new Date(convertEndDate(date)) || it.endTime === "-"))))
            : setDisplayTransactions(transactions.filter(it => ((new Date(it.startTime) >= new Date(convertStartDate(selectedStartDate)) && new Date(it.startTime) <= new Date(convertEndDate(date))) && it.assetName === selectedAssetName && (new Date(it.endTime) <= new Date(convertEndDate(date)) || it.endTime === "-"))))
    };

    const handleDropdownChange = event => {
        setSelectedAssetName(Number(event.target.value) || 'All');
        event.target.value === "All" ? setDisplayTransactions(transactions.filter(it => ((new Date(it.startTime) >= new Date(convertStartDate(selectedStartDate)) && new Date(it.startTime) <= new Date(convertEndDate(selectedEndDate))) && (new Date(it.endTime) <= new Date(convertEndDate(selectedEndDate)) || it.endTime === "-"))))
            : setDisplayTransactions(transactions.filter(it => ((event.target.value === it.assetName && new Date(it.startTime) >= new Date(convertStartDate(selectedStartDate)) && new Date(it.startTime) <= new Date(convertEndDate(selectedEndDate))) && (new Date(it.endTime) <= new Date(convertEndDate(selectedEndDate)) || it.endTime === "-"))))
    };

    useEffect(() => {
        async function onLoad() {
            setLoading(true);
            try {
                const getTransactions = await API.get("apiaada51cc", "/transactions?startDate=" + toLocalDate(selectedStartDate) + "&endDate=" + toLocalDate(selectedEndDate));                
                updateTransactions(getTransactions.data.sort((a, b) => (new Date(b.startTime) - new Date(a.startTime))));
                setDisplayTransactions(getTransactions.data);
                const getAssets = await API.get("apiaada51cc", "/assets");
                updateAssetList(getAssets.data.sort((a, b) => (a.assetId - b.assetId)));
            } catch (e) {
                insertError(e);
            }
            setLoading(false);
        }
        onLoad();
    }, []);

    return (
        <Container>
            <Grid container className={classes.root} alignItems="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <Grid item xs={4} sm={4} >
                        <KeyboardDatePicker
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="start-date-picker"
                            label="Start Date"
                            value={selectedStartDate}
                            maxDate={selectedEndDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="end-date-picker"
                            label="End Date"
                            minDate={selectedStartDate}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={4} sm={4}>
                    {assetList.length > 0 &&
                        <FormControl style={{ width: '50%' }}>
                            <InputLabel htmlFor="demo-dialog-native">Select Asset</InputLabel>
                            <Select
                                native
                                value={selectedAssetName}
                                onChange={handleDropdownChange}
                                input={<Input id="demo-dialog-native" />}
                            >
                                <option aria-label="None" value="All" />
                                {assetList.map((key) =>
                                    <option key={key.assetId} value={key.selectedAssetName}>
                                        {key.assetName}
                                    </option>
                                )}
                            </Select>
                        </FormControl>
                    }
                </Grid>
            </Grid>

            {displayTransactions.length > 0 &&
                <Table
                    tableData={displayTransactions}
                    startDateString={dateFormat(selectedStartDate)}
                    endDateString={dateFormat(selectedEndDate)}
                    startDate={new Date(selectedStartDate).toISOString().slice(0, 10)}
                    endDate={new Date(selectedEndDate).toISOString().slice(0, 10)}
                    assetName={selectedAssetName}
                />
            }
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}
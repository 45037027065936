import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Routes from "./Routes";
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF4081',
      contrastText: "#FFF",
    },
    secondary: {
      light: '#0066ff',
      main: '#5BC236',
      contrastText: '#FFF',
    },
    textPrimary: {
      main: '#FF4081',
    },
    textSecondary: {
      main: '#000',
    },
    background: {
      default: '#000',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App(props) {
  const classes = useStyles();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [username, setUsername] = useState("");

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      await Auth.currentAuthenticatedUser()
      .then(user => setUsername(user.username))
      .catch(err => console.log(err))
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut({ global: true });
    setUsername("");
    props.history.push("/login");
  }

  return (
    !isAuthenticating && (
      <React.Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
        { username!=="" && (
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}/>
              <Button color="inherit" onClick={handleLogout}>
                <PowerSettingsNewIcon /> 
                <Typography style={{paddingLeft:'6px', fontWeight: '700'}} variant="body1">Logout</Typography>
              </Button>
            </Toolbar>
          </AppBar>
         )  }
          <Routes appProps={{setUsername, username}} />
        </ThemeProvider>
      </React.Fragment>
    )
  );
}

export default withRouter(App);

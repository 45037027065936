import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MenuDialog(props) {
  const handleClose = (event) => {
    event.preventDefault();
    props.onClose(false);
  };

  async function handleConfirm(event) {
    event.preventDefault();
    const currentTime = new Date();
    if(props.tracking === "Available"){
      props.updateTransactionEndTime([{assetId: props.assetId, assetName: props.assetName}]);
    } else {
      props.createTransaction(props.assetId, props.assetName, props.tracking, 0, 0, "-", currentTime);
    }
    props.setSelectedAsset("");
    props.onClose(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xs'}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Please Verify"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" disabled={props.selectedAsset === "" ? true : false} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import * as workerTimers from 'worker-timers';

const MealMainRoomTimer = (props) => {
  const [time, setTime] = useState(props.time);
  const [myColor, setMyColor] = React.useState({ backgroundColor: 'white' })

  useEffect(() => {
    workerTimers.setTimeout(() => {
      setTime(time + 1);
      (time > 1800 && myColor.backgroundColor === "white") ? setMyColor({ backgroundColor: 'red' }) : setMyColor({ backgroundColor: 'white' });
      // Auto-refresh timer every 60 secs
    }, 1000);

  }, [time]);

  return (
    <div id={props.key} style={{ backgroundColor: myColor.backgroundColor, position: "relative", top: "-10px", left: "20px", textAlign: "center", color: 'white', fontWeight: '500' }} >
      Alarm
    </div>
  )
}

export default MealMainRoomTimer;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PubSub } from "aws-amplify";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Grid } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  root: {
    minWidth: '200px',
    border: '0.5px solid rgba(255, 64, 129, 1)'
  },
  actionGrid: {
    backgroundColor: '#5BC236',
    align: 'center',
    textAlign: 'center',
    padding: '15px',
  }
});

const Reminder = (props) => {
  const classes = useStyles();
  const [roomId, setRoomId] = React.useState('');
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setInfoOpen(false);
  };

  const handleChange = event => {
    setEnabled(true);
    setRoomId(event.target.value || '');
  };

  async function handleReminder() {
    setInfoOpen(true);
    await PubSub.publish('reminder' + roomId, { msg: 'test', value: new Date() })
    handleChange({ target: "" });
    setEnabled(false);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {props.rooms.length > 0 && <FormControl className={classes.formControl}>
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={roomId} onChange={handleChange}>
            {props.rooms.map((key) =>
              <Grid item xs={3} sm={3} key={key.roomId} style={{ padding: 0, top: 0 }}>
                <FormControlLabel key={key.roomId} value={key.roomId} control={<Radio />}
                  label={key.roomName} />
              </Grid>
            )}
            <Grid item xs={3} sm={3} key="meal" style={{ padding: 0, top: 0 }}>
              <FormControlLabel key="meal" value="meal" control={<Radio />}
                label="Meal" />
            </Grid>
          </RadioGroup>
        </FormControl>
        }
        <Snackbar open={infoOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Reminder sent!
          </Alert>
        </Snackbar>
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          color="primary"
          disabled={enabled === false}
          onClick={handleReminder}
        ><NotificationsActiveIcon fontSize="small" /> Ring Alarm</Button>
      </CardContent>
    </Card>
  )
}

export default Reminder;
import React from 'react';
import { Grid } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import MealMainRoomTimer from "../components/MealMainRoomTimer";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2962ff',
      contrastText: "#fff",
    },
  }
});

theme.overrides.MuiToggleButton = {
  root: {
    color: "#fff",
    backgroundColor: '#2962ff',
    border: '1px solid rgba(0, 0, 0, 1)',
    '&$selected': {
      border: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:hover': {
      border: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    "&:disabled": {
      border: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      backgroundColor: '#FF4081',
    },
    "&$selected&:hover": {
      border: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
};

export default function AssetGridCircles(props) {

  const handleAlignment = (event, newAlignment) => {
    event.preventDefault();
    props.setSelectedAsset(newAlignment);
  };
  
  const content = props.assetList.map((key) =>
    <Grid key={props.assetList.indexOf(key)} item style={props.override ? { position: 'absolute', marginTop: 30 + props.assetList.indexOf(key) * 10, marginLeft: -30 + props.assetList.indexOf(key) * 20 } : {}}>
      <MuiThemeProvider theme={theme}>
        { key.tracking === "Meal" && 
          <MealMainRoomTimer
            time={(new Date() - new Date(key.updatedTime)) / 1000}
            key={props.assetList.indexOf(key)}
          /> }
        <ToggleButton
          key={props.assetList.indexOf(key)}
          style={props.override ? { backgroundColor: 'rgb(255, ' + 64 * (1 + 0.2 * props.assetList.indexOf(key)) + ', ' + 129 * (1 + 0.2 * props.assetList.indexOf(key)) + ')', fontWeight: '500', left: '20px', fontSize: '1.4em', minHeight: '50px', minWidth: '60px' } : { minWidth: '60px', minHeight: '50px', fontWeight: '500', left: '20px', fontSize: '1.4em' }}
          disabled={key.tracking !== props.tracking}
          onClick={handleAlignment}
          disableFocusRipple
          disableRipple
          selected={key.assetId === props.selectedAsset}
          value={key.assetId}
        > {key.assetName} </ToggleButton>
      </MuiThemeProvider>
    </Grid>
  );
  return (
    <Grid>
      <Grid container justify="flex-start" alignItems="center" spacing={2}>{content}</Grid>
    </Grid>
  );
}
import { useState } from "react";
import { API } from "aws-amplify";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });
    }
  ];
}

export async function insertError (message) {
  const currentTime = new Date().toISOString();

  const options = {
    headers: { 'Content-Type': 'application/json',"Access-Control-Allow-Origin": "*" },
    body: {
      timestamp: currentTime,
      message: message
    }
  };

  await API.put("apiaada51cc", '/error', options).then(response => {
    // Add your code here
    return;
  }).catch(error => {
    console.log(error);
  }); 

}
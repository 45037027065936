const prod = {
  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://9u7t3haok0.execute-api.ap-southeast-1.amazonaws.com/prod",
    NAME: "apiaada51cc"
  },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_HeN4LW1xl",
    APP_CLIENT_ID: "241nor97mnlsv8863f9jahra1u",
    IDENTITY_POOL_ID: "ap-southeast-1:dc504ffa-97ba-4d0a-b54c-53a239d6c785"
  },
  iot: {
    REGION: "ap-southeast-1",
    ENDPOINT: "wss://a3q7uij0s2ed5o-ats.iot.ap-southeast-1.amazonaws.com/mqtt"
  }
};

// Default to dev if not set
const config = prod;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // PIN: "9999",
  ...config
};

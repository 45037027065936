import 'date-fns';
import React, { useEffect, useState } from "react";
import Container from '@material-ui/core/Container';
import AssetMeal from '../components/AssetMeal';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { PubSub, API } from "aws-amplify";
import SoundClip from "../components/SoundClip";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  avatar: {
    width: '50px',
    height: '50px',
    marginLeft: '10px',
    display: 'inline-block',
    textAlign: 'right',
    top: 10
  },
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  }
}));

export default function MealRoom(props) {
  const classes = useStyles();
  const [pubSub, setPubSub] = useState("");
  const [assetList, updateAssetList] = useState("");
  const [play, setPlay] = useState({ state: false, data: '' });

  useEffect(() => {
    PubSub.subscribe('reminder' + "meal").subscribe({
      next: data => { setPlay({ state: true, data: Math.random() }); },
      error: error => console.error(error),
      close: () => console.log('Done'),
    });

    PubSub.subscribe("meal").subscribe({
      next: data => { setPubSub(data); setPlay({ state: false, data: '' }); },
      error: error => console.error(error),
      close: () => console.log('Done'),
    });
  }, []);

  useEffect(() => {
    async function refresh() {
      await API.get("apiaada51cc", '/assets').then(response => {
        updateAssetList(response.data.sort((a, b) => (a.assetId - b.assetId)));
      }).catch(error => {
        console.log(error);
      });
    }
    refresh();
  }, [pubSub]);

  const mealContent = <Grid key="Meal" item xs={12} sm={12}>
    <Typography align="left" variant="h4" style={{ display: 'inline-block', height: '50px' }} >{"Meal Tracking"}</Typography>
    <Avatar src="/images/meal.png" className={classes.avatar} />
    <Box pt={2} />
    {assetList.length > 0 &&
      <AssetMeal
        override={false}
        assetList={assetList}
        tracking={"Meal"}
      />
    }
    <Box pt={2} />
  </Grid>

  return (
    <Container>
      <Grid container className={classes.root} alignItems="center">
        {mealContent}
      </Grid>
      <SoundClip play={play} />
    </Container>
  );
}
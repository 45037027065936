import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import MainRoom from "./containers/MainRoom";
import MealRoom from "./containers/MealRoom";
import SubRoom from "./containers/SubRoom";
import Transactions from "./containers/Transactions";

export default function Routes({ appProps }) {
    
  return (
    <Switch>
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      {
        appProps.username === "admin" || appProps.username === "sawadee"  || appProps.username === "sawadeek" ?
        <AuthenticatedRoute path="/" exact component={Transactions} appProps={appProps} />
        : appProps.username==="mainroom" ?
          <AuthenticatedRoute path="/" exact component={MainRoom} appProps={appProps} />
          : appProps.username==="mealroom" ?
            <AuthenticatedRoute path="/" exact component={MealRoom} appProps={appProps} />
              : <AuthenticatedRoute path="/" exact component={SubRoom} appProps={appProps} />
      }
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}

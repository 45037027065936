import React from 'react';
import Box from '@material-ui/core/Box';
import MenuDialog from "../components/MenuDialog";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import AssetGridCircles from '../components/AssetGridCircles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Avatar from '@material-ui/core/Avatar';
// import AssetMeal  from '../components/AssetMeal';

const useStyles = makeStyles({
    actionGrid: {
        border: '1px dashed rgba(0, 0, 0, 0.12)',
        backgroundColor: '#ff9c61',
        minHeight: '60px',
        align: 'center',
        textAlign: 'center',
        padding: '15px'
    },
    circleGrid: {
        minHeight: '150px'
    },
    card: {
        padding: '15px',
        border: '1px solid rgba(0, 0, 0, 1)'
    },
    root: {
        width: '50px',
        height: '50px',
        marginLeft: '10px',
        display: 'inline-block',
        textAlign: 'right',
        top: 10
    }
});

const imageList = ['/images/mc.png', '/images/period.jpg', '/images/busy.png' ,  '/images/toilet.png' , '/images/meal.png' , '/images/off.png'];

export default function TopFields(props) {
    const classes = useStyles();
    const [menuItem, setMenuitem] = React.useState("");
    const [open, setOpen] = React.useState(false);

    function handleChange(evt) {
        evt.preventDefault();
        setMenuitem(evt.currentTarget.value);
        setOpen(true);
    };

    const content = props.menuList.map((key) =>
        <Grid key={props.menuList.indexOf(key)} item xs={4} sm={4}>
            <Card key={props.menuList.indexOf(key)} className={classes.card}>
                <div key={props.menuList.indexOf(key)} className={classes.circleGrid}>
                    <Typography align="left" variant="h4" style={{ display: 'inline-block', height: '50px'}} >{key}</Typography>
                    <Avatar src={imageList[props.menuList.indexOf(key)]} className={classes.root} />
                    <Box pt={2} />
                    {props.assetList.length > 0 &&
                        <AssetGridCircles
                            override={false}
                            assetList={props.assetList.filter(it => key === it.tracking)}
                            tracking={key}
                            selectedAsset={props.selectedAsset}
                            setSelectedAsset={props.setSelectedAsset}
                        />
                    }
                </div>
                <Box pt={2} />
                <Button
                    fullWidth
                    value={key}
                    id={key}
                    className={classes.actionGrid}
                    onClick={handleChange}
                >
                    <TouchAppIcon color="disabled" fontSize="large" />
                    <Typography color="textSecondary" variant="h6">Tap Here</Typography>
                </Button>
            </Card>
        </Grid>
    );

    return (
        <Box pt={3}>
            <MenuDialog
                open={open && menuItem !== "" && props.selectedAsset !== ""}
                onClose={setOpen}
                updateTracking={props.updateTracking}
                setSelectedAsset={props.setSelectedAsset}
                createTransaction={props.createTransaction}
                createTransactionDate={props.createTransactionDate}
                selectedAsset={props.selectedAsset !== "" && props.selectedAsset}
                assetId={props.selectedAsset !== "" && props.assetList[props.selectedAsset].assetId}
                assetName={props.selectedAsset !== "" && props.assetList[props.selectedAsset].assetName}
                tracking={menuItem}
                content={props.selectedAsset !== "" && (props.assetList[props.selectedAsset].assetName + " has chosen to go " + menuItem + ".")}
            />
            <Grid container spacing={3}>
                {content}
            </Grid>
        </Box>
    );
}
import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

const MealStopwatch = (props) => {
  const [time, setTime] = useState(props.time);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((time) => time - 1);
      if (Math.floor(time % 60) === 0) window.location.reload(false);
    }, 1000);

    if (time < 120) {
      try {
        const audioEl = document.getElementById(props.assetId);
        try { audioEl.play(); }
        catch (e) { console.log(e); }
      } catch (e) {
        console.log(e);
      }
    }

    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <Typography color="textPrimary" align="center" variant={"h5"}>
      {time > 0 ? Math.floor(time / 60) : "0"}:{time > 0 ? (Math.floor(time % 60) < 10 ? "0" + Math.floor(time % 60) : Math.floor(time % 60)) : "00"}
    </Typography>
  )
}

export default MealStopwatch;
import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import * as workerTimers from 'worker-timers';
import { insertError } from "../libs/hooksLib";

const Stopwatch = (props) => {
  const [time, setTime] = useState(props.time);

  useEffect(() => {
    const timeout = workerTimers.setTimeout(() => {
      setTime(time - 1);
      // Auto-refresh timer every 60 secs
      if (props.subRoom && Math.floor(time % 60) === 0) window.location.reload(false);
      (time <= 0 && props.myColor.backgroundColor === "white") ? props.setMyColor({ backgroundColor: 'pink' }) : props.setMyColor({ backgroundColor: 'white' });
    }, 1000);

    if (props.subRoom && time < 60 && time > 55) {
      try {
        props.setPlay({ state: true, data: '' });
      } catch (e) {
        insertError(e);
      }
    } else if (!props.subRoom && time < 1 && time > -5 && time !== "") {
      try {
        props.setPlay({ state: true, data: '' });
      } catch (e) {
        insertError(e);
      }
    }
    return () => workerTimers.clearTimeout(timeout);
  }, [time]);

  return (
    <Typography color="textPrimary" align="left" variant={props.subRoom ? "h1" : "h3"}>
      {time > 0 ? Math.floor(time / 60) : "0"}:{time > 0 ? (Math.floor(time % 60) < 10 ? "0" + Math.floor(time % 60) : Math.floor(time % 60)) : "00"}
    </Typography>
  )
}

export default Stopwatch;